#splash {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  left: 0;
  top: 0;
  z-index:30001;
  transition: all 1s cubic-bezier(.165,.84,.44,1);
  &.dissapear {
    display: none;
  }
}
#loading {
  width: 156px;
  height: 75px;
  background: black;
  position: absolute;
  top: 7px;
  left: calc(50% + 1px);
  z-index: 30002;
  transform: translate(-50%,0);
  object-fit: cover;
}