﻿$color-hero-is-dark: #000000;
$color-nav-bar: #20232a;
$color-blue-highlight: #61dafb;
:root {
  --color-primary-orange: hsl(26, 97%, 61%);
  --color-primary-gray: #272727;
  --color-background-gray: #141414;
  --color-title-gray: #a4a4a4;
}

@mixin browserIsIE {
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}

@mixin removeNavBarPadding {
  @media only screen and (max-width: 1099px) {
    @content;
  }
}

@mixin reduceNavBarPadding {
  @media only screen and (max-width: 1472px) and (min-width: 1100px) {
    @content;
  }
}

@mixin renderMobileTitleText {
  @media only screen and (max-width: 449px) {
    @content;
  }
}

@mixin renderTabletTitleText {
  @media only screen and (max-width: 769px) and (min-width: 450px) {
    @content;
  }
}

@mixin renderTabletNavView {
  @media only screen and (max-width: 950px) and (min-width: 600px) {
    @content;
  }
}

@mixin renderMobileNavView {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin centerStackedColumnContent {
  @media only screen and (max-width: 769px) {
    @content;
  }
}
