﻿html, body {
  background-color: #141414;
}
code {
  background-color: #444850;
  color: $color-blue-highlight;
  padding: 0.15em 0.45em 0.15em;
  border-radius: 0.3em;
  font-size: 90%;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  font-family: Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace;
}

.flex {
  display: flex;
}

.col {
  flex-direction: column;

  &.1-8 {
    width: 12.5%;
  }

  &.1-4 {
    width: 25%;
  }

  &.3-8 {
    width: 37.5%;
  }

  &.1-2 {
    width: 50%;
  }

  &.5-8 {
    width: 62.5%;
  }

  &.3-4 {
    width: 75%;
  }

  &.7-8 {
    width: 87.5%;
  }

  &.1 {
    width: 100%;
  }
}

.row {
  flex-direction: row;
}

.is-horizontal-center {
  align-items: center;
  justify-content: center;
}

.is-notification-tile {
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem !important;
  padding-bottom: 42rem !important;

  &.is-not-found-tile {
    padding-top: 15rem !important;
    padding-bottom: 20rem !important;
  }

  .notification {
    color: #fff;
    text-align: center;
    padding: 1.75rem .25rem;
    box-shadow: 0 2px 15px 0 rgba(18,16,19,.2);

    &.is-primary {
      background-color: #54CDEE;
    }

    &.is-danger {
      background-color: #E93E60;
    }

    &.hide {
      visibility: hidden;
    }

    /* IE fix for min-heigh on tiles */
    @include browserIsIE {
      min-height: 115px;
    }

    .title {
      font-size: 2em;
      margin-left: 0.75rem;
    }

    @include renderMobileTitleText {
      svg {
        vertical-align: -0.225em;
      }

      .title {
        font-size: 1.5em;
      }

      .subtitle {
        font-size: 1.05em;
      }
    }
  }
}