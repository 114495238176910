.view-wrapper {
  padding-top: 0.25rem;
  margin-top: 5.4rem;
  @media screen and (max-width: 555px) {
    margin-top: 85px;
  }
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.view-wrapper::-webkit-scrollbar, .home::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
}

.branding-container {
  width: 100%;
  padding: 20px 15px;
  max-width: 15rem;
  margin: 0px auto;
  .branding-logo {
    height: 23px;
  }
}

.works {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 36px auto 2rem;
  @media screen and (min-width: 556px) and (orientation: landscape) {
    margin: 0px auto 1rem;
  }
}

.popup {
  position: fixed;
  z-index: 9995;
  height: 100vh;
  width: 100vw;
  background: #141414;
  left: 0;
  top: 0;
  overflow-y: scroll;
}

.work {
  cursor: pointer;
  transition-property: height, opacity, padding, transform;
  transition-duration: 2s, 0.5s, 0.9s, 0.9s;
  transition-timing-function: cubic-bezier(.165,.84,.44,1), cubic-bezier(.165,.84,.44,1), cubic-bezier(.165,.84,.44,1), cubic-bezier(.165,.84,.44,1);
  &.closed {
    transition: all 0.3s linear;
    transform: translateY(20px);
    opacity: 0;
    &.appear {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}

.pill-container {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 4rem;
  right: calc(50vw - 565px);
  height: 2rem;
  min-width: 120px;
  & .pill {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    backdrop-filter: blur(10px);
    height: 44px;
    transition: all 0.4s cubic-bezier(.165,.84,.44,1);
    & h4 {
      transition: all 0.4s cubic-bezier(.165,.84,.44,1);
      font-size: 1rem;
    }
    &:hover h4 {
      color: #e6e6e6
    }
    color: #e6e6e6;
    background-color: rgba(180, 180, 180, 0.2);
    text-align: center;
    padding: 0 1.1rem;
    border-radius: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    & h4 {
      text-align: center;
    }
    &.making-of {
      width: 7.5rem
    }
    &.close {
      width: 5rem
    }
  }
}

.shine:after {
  touch-action: none;
  animation: shine 4s linear infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -120%;
  left: -400%;
  width: 400%;
  height: 400%;
  opacity: 0;
  transform: rotate(10deg);

  background: rgba(180, 180, 180, 0.13);
  background: linear-gradient(
    to right,
    rgba(180, 180, 180, 0) 0%,
    rgba(190, 190, 190, .2) 50%,
    rgba(180, 180, 180, 0) 100%
  );
}

.shine:active:after {
  opacity: 0;
  touch-action: none;
}

@keyframes shine{
  60% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 6s, 6s, .15s;
    transition-timing-function: cubic-bezier(.165,.84,.44,1);
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}


.credits {
  display: grid;
  padding-bottom: 60px;
  grid-template-columns: 1fr 1fr;
  column-gap: 15px;
  width: 100%;
  & .credit {
    padding-bottom: 1.125rem;
  }
  .job {
    color: #8b8b8b;
  }
  .name {
    color: #e6e6e6;
  }
}

.poster {
  border-radius: 0.5rem;
}

.categories {
  display: flex;
  flex-direction: row;
  overflow-x:scroll;
  overflow-y:hidden;
  align-content: space-between;
  padding: 12px 12px;
  max-width: 22rem;
  margin: auto;
  transition: all 0.2s ease-in-out;
  height: 0px;
  opacity: 0;
  &::before {
    content: "";
    position: absolute;
    width: 5rem;
    height: 20px;
    z-index: 400;
    pointer-events: none;
    @media screen and (max-width: 555px) {
      left: 0px;
      top: 6.3rem;
      width: 3rem;
    }
    @media screen and (min-width: 555px) {
      top: 6.3rem;
      left: calc(50vw - 12rem);
    }
    // background: red;
    background: linear-gradient(90deg, rgba(20,20,20,1) 0%,rgba(20,20,20,0.5) 50%, rgba(20,20,20,0) 100%);
  }
   &::after {
    content: "";
    position: absolute;
    width: 5rem;
    height: 20px;
    z-index: 400;
    pointer-events: none;
    @media screen and (max-width: 555px) {
      right: 0px;
      top: 6.3rem;
      width: 3rem;
    }
    @media screen and (min-width: 555px) {
      top: 6.3rem;
      right: calc(50vw - 12rem);
    }
    // background: red;
    background: linear-gradient(90deg, rgba(20,20,20,0) 0%,rgba(20,20,20,0.5) 50%, rgba(20,20,20,1) 100%);
  }
  &.visible {
    height: 40px;
    opacity: 1;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display:none;
  }
  & .category {
    // width: 60px;
    flex: 1;
    white-space: nowrap;
    padding: 8px;
    letter-spacing: 0.005em;
    display: flex;
    align-items: center;
    -moz-user-select: none;
    -webkit-user-select: none;
    &.selected h4 {
      color: #e6e6e6;
    }
  }
  & .padded {
    padding: 4px;
  }
  & h4 {
    will-change: color;
    transition: all 0.5s cubic-bezier(.165,.84,.44,1);
    padding: 0;
    flex-grow: 1;
    display: flex;
    color: #8b8b8b;
    text-align: center;
    display: inline-block;
    cursor: move;
    &.selected, &:hover {
      color: #e6e6e6;
    }
  }
}

.single-work {
  will-change: margin;
  transition: margin .3s ease-in-out;
}

.bts-content {
  transition: all 0.9s cubic-bezier(.165,.84,.44,1);
  &::-webkit-scrollbar {
    display: none;
  }
}

.masonry {
  display: flex;
  margin: 40px auto;
  width: auto;
  max-width: 1200px;
}

.masonry-col {
  padding: 0px 40px;
  @media screen and (max-width: 555px) {
    padding: 0px 15px;
  }
  background-clip: padding-box;
  & .print {
    margin: 70px 0px;
    @media screen and (max-width: 555px) {
      margin: 10px 0px 60px;
    }
  }
}


img.print {
  // width: 100%;
  // height: 100%;
  will-change: padding, width, height, background-color;
  transition: all 0.5s cubic-bezier(.165,.84,.44,1);
  background-color: rgba(39, 39, 39, 0);
  object-fit: scale-down;
  border-radius: 0.5rem;
  &.open, &.opening, &.closing {
    width: 100%;
    height: 100%;
    padding: 120px 340px;
  }

  &.closed {
    padding: 0px;
  }

  &.open, &.opening {
    background-color: var(--color-background-gray);
  }
}

.back-container {
  position: absolute;
  bottom: 52px;
  left: 15px;
  @media screen and (min-width: 1000px) {
    left: calc(50vw - 565px);
  }
  @media screen and (max-width: 555px) {
    bottom: 20px;
  }
  & button.back {
    // size & position
    will-change: opacity;
    position: relative;
    height: 44px;
    width: 44px;
    opacity: 0;
    &.open, &.opening {
      &.appear {
        opacity: 1;
      }
    }
    &.closing, &.closed {
      opacity: 0;
    }
    // children
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    @media screen and (max-width: 1000px) {
      padding: 10px 10px 10px 15px;
    }
    // decorative
    cursor: pointer;
    backdrop-filter: blur(10px);
    transition: all 0.4s cubic-bezier(.165,.84,.44,1);
    border-radius: 50%;
    background-color: rgba(180, 180, 180, 0.2);
    &:hover .back-svg .st1 {
      fill: #e6e6e6;
    }
    & .back-svg {
      width: 20px;
      height: 20px;
      background-color: transparent;
      & .st1 {
        transition: all 0.2s cubic-bezier(.165,.84,.44,1);
        fill: #838383;
      }
      & .st0 {
        fill: transparent;
      }
    }
  }
}
.bts-content, .bts {
  overflow-y: scroll;
}

.work-preview {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10003;
  overflow: scroll;
  &.closed {
    pointer-events: none;
  }
  .video-container {
    height: calc(1130px * 9 / 16);
  }
}

@media screen and (min-width: 1000px) {
  .video {
    width: 100%;
  }
  .work {
    display: flex;
    flex-direction: column;
    &.closed, &.closing {
      padding: 0px;
      overflow: hidden;
    }
    &.open, &.opening {
      height: 100%;
      padding-left: calc(50vw - 565px);
      padding-right: calc(50vw - 565px);
      @media (min-height: 945px) {
        padding-top: 160px;
      }
      @media (min-height: 768px) and (max-height: 944px) {
        padding-top: 80px;
      }
      overflow-y: scroll;
      background: #141414;
      &.collapsed {
        padding: 0px calc(50vw - 565px) 0px;
        & .video-container {
          background-color: transparent;
          transform: translate(0, -800px);
          opacity: 0;
          height: 0px;
        }
      }
      & .bts-content {
        padding: 0px;
        &.collapsed {
          padding: 0px 15px;
          overflow-y: scroll;
        }
      }
      .photos {
        margin-top: 5vh;
      }
      & h1.title.making-of {
        margin: 24px 0px 60px !important;
      }
    }
  }
  .fill-parent {
    position: absolute;
  }
  .view-wrapper > .pill-container {
    display: none;
  }
  .popup {
    .title-container {
      display: flex;
      flex-direction: row;
      .title {
        flex-grow: 1;
      }
      .pill-container {
        position: relative;
        margin-top: 0.67rem;
        margin-bottom: 1.5rem;
        height: 2rem;
        & .pill {
          color: #e6e6e6;
          background-color: #272727;
          padding: 0.5rem 0.7rem;
          border-radius: 1rem;
          & h4 {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .video-container.collapsed {
    margin-top: 0px;
  }

  .credits{
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1000px) and (min-width: 556px) {
  .works {
    padding: 0px calc(50vw - 212px) 0px;
  }

  .pill-container, .back-container {
    position: fixed;
  }

  .space {
    height: calc(((var(--vh, 1vh) * 100) - 232px ) / 2) ;
    &.collapsed {
      height: 0;
    }
  }

  .work {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    overflow:hidden;
    touch-action: none;
    .video-container.making-of {
      box-shadow: 0px 0px 50px 1px rgba(0,0,0, 0.8),
        0px 0px 30px 5px rgba(0,0,0, 0.8);
      z-index: 9999;
    }
    &.closed, &.closing {
      padding: 0.625rem 0rem 3rem;
    }
    &.open, &.opening {
      height: 100% !important;
      background: #141414;
      &.collapsed {
        padding: 0px !important;
      }
      & .bts-content {
        padding: 0px;
        overflow: scroll;
        & .photos {
          padding-top: 2rem;
          position: relative;
        }
        &.collapsed {
          padding: 0px 15px;
        }
      }
    }
    h1 {
      margin-top: 18px;
      &.title.making-of {
        font-size: 19px;
        margin: 24px 0px 30px;
      }
    }
    h2.subtitle {
      margin-top: -19px !important;
    }
  }
  .poster {
    height: 100%;
    flex-shrink: 1;
  }
  .back-container {
    bottom: calc(50% - 22px);
  }

  .single-work {
    flex-direction: column;
    & .work__selected {
      flex: 1;
      flex-direction: column;
      & .bts-title {
        flex-direction: column-reverse;
        flex: 1;
        padding: 0 15px 4rem;
      }
    }
  }
  .pill-container {
    bottom: 2rem;
    right: 15px;
    z-index: 9996;
  }
}

@media screen and (max-width: 555px) {
  .space {
    height: calc(((var(--vh, 1vh) * 100) - 232px ) / 2) ;
    &.collapsed {
      height: 0;
    }
  }
  .pill-container, .back-container {
    position: fixed;
  }

  .work {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    .video-container.making-of {
      box-shadow: 0px 0px 50px 1px rgba(0,0,0, 0.8),
        0px 0px 30px 5px rgba(0,0,0, 0.8);
      z-index: 9999;
    }
    &.closed, &.closing {
      padding: 0.625rem 0rem 5rem;
    }
    &.open, &.opening {
      height: 100% !important;
      background: #141414;
      padding: 15rem 15px 0px !important;
      &.collapsed {
        padding: 0px !important;
      }
      & .bts-content {
        padding: 0px;
        overflow: scroll;
        & .photos {
          padding-top: 2rem;
          position: relative;
        }
        &.collapsed {
          padding: 0px 15px;
        }
      }
    }
    h1 {
      margin-top: 18px;
      &.title.making-of {
        font-size: 19px;
        margin: 24px 0px 30px;
      }
    }
    h2.subtitle {
      margin-top: -19px !important;
    }
  }
  .works {
    padding: 0 1rem;
  }
  .poster {
    max-height: 232px;
    flex-shrink: 1;
  }

  .single-work {
    flex-direction: column;
    & .work__selected {
      flex: 1;
      flex-direction: column;
      & .bts-title {
        flex-direction: column-reverse;
        flex: 1;
        padding: 0 15px 4rem;
      }
    }
  }
  .pill-container {
    bottom: 2rem;
    right: 15px;
    z-index: 9996;
  }
}

.m16 .letter {
  display: inline-block;
}

#progress-bar {
  height: 6px;
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(155, 155, 155, 0.6);
}

.block {
  width: calc(100vw - 168px);
  height: 52vw;
  background-color: #141414;
  position: relative;
}

.hide-scrollbar {
  /*FireFox*/
  scrollbar-width: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hide-scrollbar::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
}

* {
  scrollbar-width: none;
}