
h1, h2, h3, h4, h5, h6, p, a, title, button.menu, button.category, .men, button {
  font-family: 'Work Sans', Verdana, Arial, sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 500;
}

a {
  text-decoration: underline;
  &.no-underline {
    text-decoration: none;
  }
}

h1 {
  font-size: 1.25rem;
  letter-spacing: -0.2px;
  font-weight: 500;
  line-height: 1.3;
  color: #e6e6e6;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.27;
  color: #e6e6e6;
}

h2 {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.33;
  color: #8b8b8b;
}

.subtitle {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
  color: #8b8b8b;
}

.category {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
  color: #8b8b8b;
}

h3 {
  font-size: 1.125rem;
  font-weight: 500;
  color: white;
}

h4 {
  font-size: 18px;
  letter-spacing: normal;
  color: #8b8b8b;
}


@media (max-height: 420px) {
  .title {
    font-size: 1.25rem;
  }
  .subtitle {
    padding-top: 0rem;
  }
}


@media (max-width: 555px) {
  .menu-content {
    & h1 {
      font-size: 19px;
      line-height: 1.4em;
      hyphens: auto;
    }
    & .menu {
      font-size: 20px;
    }
    .instagram, .facebook, .vimeo {
      h2 {
        font-size: 18px !important;
      }
    }
    > h1 + h2 {
      font-size: 18px;
      padding-top: 3px;
    }
  }
}

@media (min-width: 1300px) {
  h1, .title {
    font-size: 23px;
    margin: .75rem 0px 0.5rem;
  }
  .menu-content {
    & h1 {
      font-size: 19px;
      line-height: 1.4em;
    }
    & h2, .menu {
      font-size: 19px;
    }
    .facebook, .instagram, .vimeo {
      h2:hover {
        color: #ececec;
      }
    }
  }
  .category {
    font-size: 19px;
  }
  h4 { font-size: 19px; }
  h2.subtitle {
    font-size: 19px;
    padding-top: 1px;
  }
}