html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Remove figure margin
figure {
  margin: 0;
}

button {
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
}
