﻿// .hero {
//   // background-color: $color-hero-is-dark;
//   min-width: 100vw;
//   min-height: 100vh;
//   min-height: -webkit-fill-available;

//   .statement {
//     @media only screen and (max-width: 600px) {
//       min-height: 40vh;
//     }
//   }

//   .hero-body {
//     padding: 0px;
//     overflow: hidden;
//     svg {
//       color: $color-blue-highlight;
//     }
//     display: flex;
//     align-content: center;
//     justify-content: center;

//     .container {

//       hr {
//         background-color: hsla(0,0%,100%,.3);
//         height: 1px;
//         margin: 0 auto 1.75em auto;
//         width: 65%;
//       }
//     }
//   }

//   .title {
//     color: #fff;
//     margin-bottom: 2.55rem;

//     &.blog-title {
//       font-size: 3em;
//       font-family: Segoe Script;
//       letter-spacing: 0.015em;
//       padding-bottom: 1.5rem;
//       margin: auto;

//       @include renderMobileTitleText {
//         font-size: 2em;
//       }
//     }
//   }
// }

#reels {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9998;
  object-fit: cover;
}

.dashboard-content {
  overflow: hidden;
  padding-top: 2rem;
  padding-bottom: 2rem;

  code {
    background-color: rgba(97, 218, 251, 0.25);
    color: black;
  }

  hr {
    background-color: hsla(0,100%,0%,.125);
    margin: 0.5rem 0;
    height: 1px;
  }

  > .columns {
    > .column {
      padding: 2rem !important;

      > .title {
        color: #6d6d6d;
      }
    }

    @media (min-width: 769px) {
      > hr {
        display: none;
      }
    }
  }

  .work {
    > .title {
      color: hsl(1, 0%, 100%);
      text-align: center;
      font-size: 2rem;
      font-family: cursive;
    }
  }
}
