
.rubberBand-animation {
  animation: rubberBand 1s;
}

.spinClockwise-animation {
  animation: spinClockwise infinite 10s linear;
}

.pageSlideDown-animation {
  animation: pageEnterSlideDown 0.35s cubic-bezier(0.4, 0, 0, 1.5) both;
}

@keyframes spinClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pageEnterSlideDown {
  from {
    opacity: 0;
    transform: translate3d(0, -150px, 0);
  }
  to {
    opacity: 1;
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}